import { motion } from "framer-motion";

const AnimatedTitle = (props) => {
    
    return (
        <div>
            {props.nameItems.map((item,index) => (
                <motion.button
                    className = "name"
                    key = {index}
                    whileHover={{
                        color: "#55C2C3",
                        scale: 1.2,
                    }}
                    >{item === ' ' ? '\u00A0' : item} 
                </motion.button>
                
                ))}
        </div>
    );
};

export default AnimatedTitle;