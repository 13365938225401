import { motion } from "framer-motion";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faGithub } from '@fortawesome/free-brands-svg-icons'

const ProjectDisplay = (props) => {
    
    return (
        
            <motion.div
                className='projectContainer'
                whileHover={{
                    scale: 1.03,

                }}
            >
            <p className='projectTitle'>{props.title}</p>
            <p className='projectDesc'>{props.projectDesc1}</p>
           <p className='projectDesc'>{props.projectDesc2}</p>

            <div className='projectButtonsContainer'>
                <a className = "projectLink" href={props.gitHub} target="_blank" rel="noreferrer">
                <motion.button 
                    className='projectButton'
                    whileHover={{
                        scale: 1.1,
                    }}
                    >
                        <p className = "projectLink"> <FontAwesomeIcon className= "projectGit" icon={faGithub} /> 
                        GitHub</p>   
                        
                </motion.button>
                </a>
                {props.liveDemo &&
                 <a className = "projectLink" href={props.demo} target="_blank" rel="noreferrer">
                    <motion.button 
                        className='projectButton'
                        whileHover={{
                            scale: 1.1,

                        }}
                        >  
                         <p className = "projectLink">Live Demo</p>   
                    </motion.button>
                    </a>
                }
            </div>
            <img src={props.img} className = {props.imgClass} alt = "cocktail-site"></img>
            
            <div className='techDisplay'>
                {props.tech.sort().map((item, index) => {
                    return (
                        <p className="projectTech" key={index}>{item}</p>
                    );      
                })}
            </div>
            
        
            </motion.div>
       
    );
};

export default ProjectDisplay;