import React from 'react'

import AnimatedPage from './Functions/AnimatedPage';
import AnimatedTitle from './Functions/AnimatedTitle';
import ProjectDisplay from './Functions/ProjectDisplay';

import CocktailDB from './Files/CocktailDB.PNG';
import Palmacology from './Files/Palmacology.PNG';
import TailTogether from './Files/tail.png'
import StudentDB from './Files/StudentDB.PNG'

function Projects(){

    const projects = [
        {
            title: "CockTail DB",
            projectDesc1: "React application with Google Firebase to display different cocktails with their ingredients and instructions with the help of TheCocktailDB.com API.",
            projectDesc2: "Users can browse and search for different drinks and login with their google account to save their favorite drinks",
            gitHub: 'https://github.com/jsison2021/CockTail-Recipes',
            liveDemo: true,
            demo: 'https://stalwart-custard-f65802.netlify.app/',
            img: CocktailDB,
            imgClass: "projectPicture",
            tech: ["REACT","REACT-ROUTER-DOM", "GOOGLE FIREBASE AUTH", "GOOGLE FIREBASE FIRESTORE", "BOOTSTRAP", "FRAMER-MOTION","AXIOS","NODE JS","VISUAL STUDIO CODE"]
        },
        {
            title: "Palmacology",
            projectDesc1: "MERN Stack application with Google Firebase that helps nursing students learn pharmacology in a fun and easy way with single answer, multi-choice answer, and matching questions",
            projectDesc2: "Authorized users can upload more questions/courses and manage other users",
            gitHub: 'https://github.com/jsison2021/Med-App',
            liveDemo: false,
            demo: false,
            img: Palmacology,
            imgClass: "projectPicture",
            tech: ["REACT","REACT-ROUTER-DOM", "GOOGLE FIREBASE AUTH","MONGODB", "EXPRESS","BOOTSTRAP","AXIOS","NODE JS","VISUAL STUDIO CODE"]
        },
        {
            title: "Student DB",
            projectDesc1: "ASP.NET Core Project that allows users to create, edit, and delete student information from the database",
            projectDesc2: "",
            gitHub: 'https://github.com/jsison2021/StudentDB.git',
            liveDemo: false,
            demo: "https://studentdbwebjs.azurewebsites.net/",
            img: StudentDB,
            imgClass: "projectPicture",
            tech: ["C#","AZURE","ASP.NET CORE MVC", "SSMS", "SQL","BOOTSTRAP","VISUAL STUDIOS","ENTITY-FRAMEWORK-CORE"]
        },
        {
            title: "Tail Together",
            projectDesc1: "IOS application that allows users to create, join, and dicuss events.",
            projectDesc2: "Users can customize their profile and chat with other users",
            gitHub: 'https://github.com/jsison2021/Tail-Together.git',
            liveDemo: false,
            demo: false,
            img: TailTogether,
            imgClass: "projectPicture2",
            tech: ["IOS","XCODE","SWIFT", "BACK4APP", "PARSE"]
        }


    ]
    return(
        <div className='center'>
            <AnimatedPage>
                <AnimatedTitle nameItems = {["P","r","o","j","e","c","t","s"]}></AnimatedTitle>
                <div className='row'>

                {projects.map((item, index) => {
                    return (
                        <div className='col-md-6' key = {index}>
                            <ProjectDisplay
                                title = {item.title}
                                projectDesc1 = {item.projectDesc1}
                                projectDesc2 = {item.projectDesc2}
                                gitHub = {item.gitHub}
                                liveDemo = {item.liveDemo}
                                demo = {item.demo}
                                img = {item.img}
                                imgClass = {item.imgClass}
                                tech = {item.tech}
                            >
                            </ProjectDisplay>
                        </div>
                
                    );
                    })}
      
                </div>
    
            </AnimatedPage>
        </div>
    )
   
}

export default Projects;