import Navbar from "./MainNavbar";
import './App.css';
import { BrowserRouter } from "react-router-dom";

function App() {
  return (
    <BrowserRouter>
      <div className="body">
        <Navbar />
      </div>
    </BrowserRouter>
  );
}

export default App;
