import React from 'react'
import { motion } from "framer-motion";

import rps from "./Files/rps.png"
import rpsls from "./Files/rpsls.png"
import graduation from "./Files/Graduation.png"
import Molli from "./Files/Molli.png"

import AnimatedPage from './Functions/AnimatedPage';
import AnimatedTitle from './Functions/AnimatedTitle';


function About(){
   
    return(
        <div className='center'>
            <AnimatedPage>
        
            <AnimatedTitle nameItems = {["A","b","o","u","t"]}></AnimatedTitle>
                
            <div className='row'>
                <div className='col-sm-6'>
                    <motion.div
                    className='aboutDesc'
                    whileHover={{
                        translateY: "-10px"
                        }}
                    >
                        My passion for software development sparked in high school when I took an introductory programming course and learned how to create a simple game of Rock, Paper, Scissors (Lizard, Spock).
                    </motion.div>
                </div>
                <div className='col-sm-6'>
                    <div className="thumbnail">
                        <div className="picture1">
                            <img className="rps" src= {rps} alt="rps"></img>
                        </div>
                        <div className="picture2">
                            <img className="rpsls" src={rpsls} alt="rpsls"></img>
                        </div>  
                    </div>
                </div>
            </div>
            <div className='row'>
                <div className='col-sm-6'>
                    <motion.div
                        className= 'aboutIcon'
                        whileHover={{
                            scale: 1.1,
                        }}
                    > 
                        <img className="graduation" src= {graduation} alt="FAU Graduation"></img>
                    </motion.div>
                </div>
                <div className='col-sm-6'>
                    <motion.div
                    className='aboutDesc'
                    whileHover={{
                        translateY: "-10px"
                        }}
                    >
                       
                    Since then, I have graduated from Florida Atlantic University with a Bachelor of Science in Computer Science and now currently pursuing my Master of Science in Artificial Intelligence.
                    </motion.div>
                  
                </div>
            </div>

            <div className='row'>
                <div className='col-sm-6'>
                    <motion.div
                    className='aboutDesc'
                    whileHover={{
                        translateY: "-10px"
                        }}
                    >
                    If I am not programming, I like to spend my time hiking with my dog, paddle boarding, or going to concerts.
                
                    </motion.div>
                   
                </div>
                <div className='col-sm-6'>
                    <motion.div
                        className= 'aboutIcon'
                        whileHover={{
                            scale: 1.1,
                        }}
                    > 
                        <img className="molli" src= {Molli} alt="Molli the chihuaua"></img>
                    </motion.div>
                </div>
            </div>
            </AnimatedPage>
        </div>
    )
   
}

export default About;