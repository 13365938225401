import 'bootstrap/dist/css/bootstrap.min.css';

import Home from './components/Home';
import About from './components/About';
import Projects from './components/Projects';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBars, faX } from '@fortawesome/free-solid-svg-icons'
import { AnimatePresence } from "framer-motion";


import {
  NavLink,
  Routes,
  Route,
  useLocation
} from "react-router-dom";

import './App.css';
import { useState } from 'react';


function MainNavbar() {

  const [menu,setMenu] = useState(true)
  const [symbol,setSymbol] = useState(faBars)

  const location = useLocation();

  let menuChange = () =>{
    if (menu === true){
      setMenu(false)
      setSymbol(faX)
    }
    if (menu === false){
      setMenu(true)
      setSymbol(faBars)
    }
  }

  
  return (
    
   <div>
   
      <div className='mainbar'>
        <div className='regularMenu'>
          <NavLink className = {({ isActive }) => (isActive ? "barlinks-active" : "barlinks")} to = "/" >Home</NavLink>
          <NavLink className = {({ isActive }) => (isActive ? "barlinks-active" : "barlinks")} to = "/About" >About</NavLink>
          <NavLink className = {({ isActive }) => (isActive ? "barlinks-active" : "barlinks")} to = "/Projects" >Projects</NavLink>
          
        </div>
      </div>
      
      <div className='mobileMenu'>
        <button className='menuButton'onClick={menuChange}><p className='menuDesc'>Menu</p><FontAwesomeIcon icon={symbol} /></button>
        <div className="menu">
          {menu ? <NavLink></NavLink> : <NavLink className = {({ isActive }) => (isActive ? "barlinks-active" : "barlinks")} to = "/" onClick={menuChange}>Home</NavLink>}
          {menu ? <NavLink></NavLink> : <NavLink className = {({ isActive }) => (isActive ? "barlinks-active" : "barlinks")} to = "/About" onClick={menuChange}>About</NavLink>}
          {menu ? <NavLink></NavLink> : <NavLink className = {({ isActive }) => (isActive ? "barlinks-active" : "barlinks")} to = "/Projects" onClick={menuChange} >Projects</NavLink>}
        
        </div>
      </div>
   
    <div className= 'allContent'>
      <AnimatePresence mode='wait'>
        <Routes key={location.pathname} location={location}>
            <Route path = "/" exact element = {<Home/>}/>
            <Route path = "/About" exact element = {<About/>}/>
            <Route path = "/Projects" exact element = {<Projects/>}/>
        

          </Routes>
      </AnimatePresence>
    </div>

    <div className='bottombar'>
      <p>© 2024 JUSTIN SISON All Rights Reserved</p>
    </div>

  
    </div>
    
  );
}

export default MainNavbar;
